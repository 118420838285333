import Nats from '@pepita-node/nats';
import type { GetServerSideProps } from 'next/types';

import { setCacheControlHeader } from 'src/libs/html-cache';

import { getAgencyServerSideProps } from 'src/utils/server/getAgencyServerSideProps';
import { getServerSidePropsWithRedirect } from 'src/utils/server/multiLanguagesServerSideRedirect';

import { Agency } from 'src/views/Agency';

export default Agency;

const handler: GetServerSideProps = async (ctx) => {
  ctx.res.setHeader('grp', 'DAG'); // Dettaglio Agenzia

  setCacheControlHeader(ctx, 'AGENCY_PREFIXES');

  return getAgencyServerSideProps(ctx);
};

const nats = Nats.getInstance();

export const getServerSideProps = nats.traceWrapper<GetServerSideProps>(
  {
    label: 'agency',
    scope: 'page',
  },
  getServerSidePropsWithRedirect(handler)
);
